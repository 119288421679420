import React, { useState } from "react";
import PatternLayout from "../../components/layouts/pattern-layout";
import Container from "../../../packages/Container/src";
import Flex from "../../../packages/Flex/src";
import Div from "../../../packages/Div/src";
import RatioContainer from "../../../packages/RatioContainer/src";
import Switch from "../../../packages/Switch/src";
import Button from "../../../packages/Button/src";
import { Menu, MenuItem, MenuDisclosure, MenuPanel } from "../../../packages/Menu/src";

var ControlsBarPattern = function ControlsBarPattern() {
  var _useState = useState(false),
      checked = _useState[0],
      setChecked = _useState[1];

  return React.createElement(PatternLayout, null, React.createElement(Container, {
    size: "sm",
    mt: "5rem"
  }, React.createElement(Flex, {
    alignItems: "center",
    justifyContent: "space-between",
    mb: "1.5rem"
  }, React.createElement(Flex, null, React.createElement(Div, {
    mr: "0.75rem"
  }, React.createElement(Menu, null, React.createElement(MenuDisclosure, {
    as: Button,
    variant: "primary"
  }, "Region"), React.createElement(MenuPanel, null, React.createElement(MenuItem, null, "Americas"), React.createElement(MenuItem, null, "Europe"), React.createElement(MenuItem, null, "Asia"), React.createElement(MenuItem, null, "Africa"), React.createElement(MenuItem, null, "Oceania")))), React.createElement(Div, {
    mr: "0.75rem"
  }, React.createElement(Menu, null, React.createElement(MenuDisclosure, {
    as: Button
  }, "Income"))), React.createElement(Div, {
    mr: "0.75rem"
  }, React.createElement(Menu, null, React.createElement(MenuDisclosure, {
    as: Button
  }, "Time period")))), React.createElement(Div, null, React.createElement(Switch, {
    name: "switcher",
    label: "Show flags",
    checked: checked,
    onChange: function onChange() {
      setChecked(!checked);
    }
  }))), React.createElement(RatioContainer, {
    ratio: 1 / 2,
    bg: "grey.100"
  }, "Visualisation 3")));
};

export default ControlsBarPattern;